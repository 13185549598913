import './App.css';
import Home from './routes/Home';
import RootSecurity from './routes/Root';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/root" element={<RootSecurity />} />
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
