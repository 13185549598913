function RootSecurity() {
  return (
    <div className="Home">
      <div style={{maxWidth: '1024px', margin: 'auto'}}>
        <img src='https://cr-client-assets.s3.ap-southeast-1.amazonaws.com/public_assets/Notification_image.png' alt="page" style={{maxWidth: '100%'}} />
      </div>
    </div>
  );
}

export default RootSecurity;
